import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  deviceByTypeList: [],
  coverageList: [],
  eoxList: [],
  LDoSList: [],
};

// facts { id y status , lo de mas lo saca del asset}

const slice = createSlice({
  name: 'lifecycle',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetails = action.payload;
    },

    // RESET ERROR
    resetError(state) {
      state.isLoading = false;
      state.error = false;
      state.errorDetails = {};
    },
    setDeviceByTypeList(state, action) {
      state.isLoading = false;
      state.deviceByTypeList = action.payload;
    },
    setCoverageList(state, action) {
      state.isLoading = false;
      state.coverageList = action.payload;
    },
    setLDoSList(state, action) {
      state.isLoading = false;
      state.LDoSList = action.payload;
    },
    setEoxList(state, action) {
      state.isLoading = false;
      state.eoxList = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  stopLoading,
} = slice.actions;


export function getDevicesByType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/lifecycle/devicebytypelist`);
      dispatch(slice.actions.setDeviceByTypeList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEoxList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/lifecycle/eoxlist`);
      dispatch(slice.actions.setEoxList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLDoSList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/lifecycle/ldoslist`);
      dispatch(slice.actions.setLDoSList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCoverageList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/lifecycle/coveragelist`);
      dispatch(slice.actions.setCoverageList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
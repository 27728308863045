import Ajv from 'ajv';

export const mapConfig = {
  apiGoogle: process.env.REACT_APP_MAP_GOOGLE,
  apiMapBox: process.env.REACT_APP_MAP_MAPBOX
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const axiosConfig = {
  env: process.env.ENVIRONMENT,
  baseURL: window.location.origin.replace('3006', '3000')
};

export const getBp2 = () => {
  if (window.location.origin.indexOf('localhost') > -1) {
    return {url: window.location.origin.replace('3006', '8080') }
  }
  return {url: `${window.location.origin}/proc/` }
};

export const basePath = {
  url: window.location.origin.replace('3006', '3000')
};

function getCid() {
  if (window.location.origin.indexOf('demo') > -1) {
    return '7vs24jbswfr8f8yuusvwh8bc';
  }

  if (window.location.origin.indexOf('localhost') > -1) {
    return 'ymjgcbw9gvex4ppwf3zxf76e';
  }

  if (window.location.origin.indexOf('macro') > -1) {
    return 'k8v42rsuzmt2yutac535vp5y';
  }

  if (window.location.origin.indexOf('galicia') > -1) {
    return 'ktmwu3p88x59dysnc9w8yau4';
  }

  if (window.location.origin.indexOf('next') > -1) {
    return 'v4fsc9hzswsbhd84mdq249jw';
  }

  if (window.location.origin.indexOf('app.natroy') > -1) {
    return 'afqhwz6zc5hsnwpr9bbpevbn';
  }
  return '';
}

export const cisco = {
  client_id: getCid(),
  redirect: `${window.location.origin}/callback`,
  logout: `${window.location.origin}/callback`
};

let validator;

export function getValidator() {

  if (validator) return validator;

  const s = new Ajv();
  const schema3 = {
    type: 'array',
    additionalProperties: false,
    minItems: 1,
    items: {
      additionalProperties: false,
      properties: {
        cmd: {
          type: 'string',
          minLength: 1
        },
        expect: {
          type: 'string'
        },
        id: {
          type: 'string'
        }
      },
      required: ['cmd']
    }
  };
  validator = s.compile(schema3);

  return validator;
}

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

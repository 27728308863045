import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  status: '',
  currentPolicy: undefined,
  notifications: null,
  policiesList: [],
  // a map of policies indexed by id.
  policiesMap: {},
  idSelected: -1,
  severity: 'all',
  query: ''
};

const slice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    setPolicy(state, action) {
      // I only add the policy to the policiesMap
      const policy = action.payload;
      state.currentPolicy = policy;
    },

    setPolicyList(state, action) {
      state.isLoading = false;
      state.policiesList = action.payload;
    },

    // TODO: check if this is needed.
    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    onChangeStatus(state, action) {
      state.status = action.payload;
    },
    setSeverity(state, action) {
      state.severity = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  setIdSelected,
  setPolicy,
  setPolicyList,
  setSeverity,
  setQuery
} = slice.actions;

// DEFAULTS

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

export function getPoliciesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/policies`);

      dispatch(slice.actions.setPolicyList(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function getPolicy(policyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/policy/${policyId}`);
      dispatch(slice.actions.setPolicy(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function savePolicy(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/policies`, body);
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
      if (response.data?.data?.id) {
        return response.data.data.id;
      } else {
        return undefined;
      }
    } catch (error) {
      callOnSubmitted(false);
      dispatch(slice.actions.stopLoading());
      errorDialog.msg = 'Error saving policy';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function updatePolicy(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/policy/${body.id}`, body);
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
    } catch (error) {
      callOnSubmitted(false);
      dispatch(slice.actions.stopLoading());
      errorDialog.msg = 'Error patching policy';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function deletePolicy(id, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/policy/${id}`);
      dispatch(getPoliciesList());
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.msg = 'Error saving policy';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function searchPolicies(s) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/policies/group/${s}`);
      if (response?.data?.length > 0) {
        dispatch(slice.actions.setPolicyList(response.data));
      }
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function searchPoliciesByQuery(query, severity) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {};
      if (severity !== 'all') {
        p.severity = severity;
      }
      const response = await axios.get(
        `/api/policies/search/${query ? query : '_all_'}`,
        { params: p }
      );
      dispatch(slice.actions.setPolicyList(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

import { PATH_REMEDIATION  } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

const RemediationRoutes = {
  path: PATH_REMEDIATION.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.change_requests,
      component: lazy(() => import('../views/pages/ChangeRequestView/Main'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.create_change_request,
      component: lazy(() => import('../views/pages/ChangeRequestView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.create_change_request_nci,
      component: lazy(() => import('../views/pages/ChangeRequestView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.edit_change_request,
      component: lazy(() => import('../views/pages/ChangeRequestView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.issues,
      component: lazy(() => import('../views/pages/Issues/IssueView/Main'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.create_issue,
      component: lazy(() => import('../views/pages/Issues/IssueView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.edit_issue,
      component: lazy(() => import('../views/pages/Issues/IssueView/CreateView'))
    },
    ,
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.sr_issues,
      component: lazy(() => import('../views/pages/Issues/SrIssueView/Main'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.sr_create_issue,
      component: lazy(() => import('../views/pages/Issues/SrIssueView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.sr_edit_issue,
      component: lazy(() => import('../views/pages/Issues/SrIssueView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.in_issues,
      component: lazy(() => import('../views/pages/Issues/InIssueView/Main'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.in_create_issue,
      component: lazy(() => import('../views/pages/Issues/InIssueView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.in_edit_issue,
      component: lazy(() => import('../views/pages/Issues/InIssueView/CreateView'))
    },
    {
      exact: true,
      path: PATH_REMEDIATION.remediation.issues_by_asset,
      component: lazy(() => import('../views/pages/Issues/IssueView/Main'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default RemediationRoutes;

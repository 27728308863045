import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  detail: '',
  targetsList: [],
  currentTarget: undefined,
};

const slice = createSlice({
  name: 'target',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setTargetsList(state, action) {
      state.targetsList = action.payload;
      state.isLoading = false;
    },

    setCurrentTarget(state, action) {
      state.currentTarget = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setTargetsList, setCurrentTarget } = slice.actions;

export function getTargetsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/targets`);
      dispatch(slice.actions.setTargetsList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTargetById(tagId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/target/${tagId}`);
      dispatch(slice.actions.setCurrentTarget(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveTarget(body, callOnSubmitted) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.post(`/api/target`, body);
        dispatch(slice.actions.stopLoading());
        callOnSubmitted(true);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function updateTarget(body, callOnSubmitted) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.patch(`/api/target/${body.id}`, body);
        dispatch(slice.actions.stopLoading());
        callOnSubmitted(true);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function deleteTargetById(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.delete(`/api/target/${id}`);
        dispatch(getTargetsList())
        dispatch(slice.actions.stopLoading());
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }



import { PATH_GENERAL  } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

const GeneralRoutes = {
  path: PATH_GENERAL.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    {
      exact: true,
      path: PATH_GENERAL.entities.user_credentials,
      component: lazy(() => import('../views/pages/CredentialView/Main'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.user_credential,
      component: lazy(() => import('../views/pages/CredentialView/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.users,
      component: lazy(() => import('../views/pages/UserView/Main'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.create_user,
      component: lazy(() => import('../views/pages/UserView/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.edit_user,
      component: lazy(() => import('../views/pages/UserView/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.groups,
      component: lazy(() => import('../views/pages/GroupView/Main'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.create_group,
      component: lazy(() => import('../views/pages/GroupView/DetailView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.edit_group,
      component: lazy(() => import('../views/pages/GroupView/DetailView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.account,
      component: lazy(() => import('../views/pages/AccountView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.agents,
      component: lazy(() => import('../views/pages/Agent/Main'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.create_agent,
      component: lazy(() => import('../views/pages/Agent/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.view_agent,
      component: lazy(() => import('../views/pages/Agent/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.tftp,
      component: lazy(() => import('../views/pages/Tftp'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.repositories,
      component: lazy(() => import('../views/pages/RepositoryView/Main'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.create_repository,
      component: lazy(() => import('../views/pages/RepositoryView/CreateView'))
    }, 
    {
      exact: true,
      path: PATH_GENERAL.entities.edit_repository,
      component: lazy(() => import('../views/pages/RepositoryView/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.environments,
      component: lazy(() => import('../views/pages/EnvironmentView/Main'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.create_environment,
      component: lazy(() => import('../views/pages/EnvironmentView/CreateView'))
    },
    {
      exact: true,
      path: PATH_GENERAL.entities.edit_environment,
      component: lazy(() => import('../views/pages/EnvironmentView/CreateView'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default GeneralRoutes;

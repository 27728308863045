import axios from '../../utils/axios';
import AxiosError from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { decode } from 'jsonwebtoken';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: undefined,
  ciscoUser: {},
  user: {},
  error: null,
  menu: []
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = mapUser(action.payload.user);
      state.menu = action.payload.menu;
    },

    loginSuccess(state, action) {
      state.user = mapUser(action.payload.user);
      state.menu = action.payload.menu;

      if (state.user.check_pass === false) {
        state.token = action.payload.access_token;
        state.isAuthenticated = true;
      }
    },

    reset(state, action) {
      state.error = null;
    },

    loginFailed(state, action) {
      state.isAuthenticated = false;
      state.error = action.payload;
    },

    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.access_token;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.token = undefined;
      state.user = null;
      state.error = null;
    },

    updateUser(state, action) {
      if (action.payload['check_pass']) {
        return;
      }
      if (action.payload['empresa']) {
        state.user.empresa = action.payload['empresa'];
      } else {
        state.user.notify_by_whatsapp = action.payload['notify_by_whatsapp'];
        state.user.notify_by_mail = action.payload['notify_by_mail'];
        state.user.log_level = action.payload['log_level'];
      }
    },
    setCiscoUser(state, action) {
      state.ciscoUser = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { reset, checkPassword } = slice.actions;

function mapUser(back) {
  if (!back) {
    return null;
  }
  let user = {
    id: -1,
    displayName: 'Admin Doe',
    email: 'admindoe@natroy.io'
  };
  user.id = back.id;
  user.displayName = `${back.firstName} ${back.lastName}`;
  user.email = back.email;
  user.phone = back.phone;
  user.roles = back.roles;
  user.groups = back.groups;
  user.notify_by_whatsapp = back.notify_by_whatsapp;
  user.notify_by_mail = back.notify_by_mail;
  user.log_level = back.log_level;
  user.empresa = back.empresa;
  user.check_pass = back.check_pass;

  // change
  user.photoURL = '/static/images/avatars/avatar_default.jpg';
  return user;
}

function getToken(accessToken) {
  if (!accessToken) {
    return [false, 0];
  }
  // DECODE ESTA MAL no existe,.
  const decoded = decode(accessToken);
  const currentTime = Date.now() / 1000;

  return [decoded.exp > currentTime, decoded.sub];
}

const setSession = (accessToken, menu) => {
  if (menu !== undefined) {
    window.localStorage.setItem('menu', JSON.stringify(menu));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    window.localStorage.removeItem('menu');
  }

  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    window.localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
export function login({ email, password }) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/autho', {
        email,
        password
      });
      if (response && response.status === 200) {
        const { access_token, user, menu } = response.data;
        setSession(access_token, menu);
        dispatch(slice.actions.loginSuccess({ user, access_token, menu }));
      } else {
        dispatch(
          slice.actions.loginFailed({
            message: 'Wrong credentials '
          })
        );
      }
    } catch (e) {
      console.log(`e: ${e}`);
      if (e.response.status === 401) {
        dispatch(
          slice.actions.loginFailed({
            message: 'Wrong credentials '
          })
        );
      }
    }
  };
}

export function register({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    // TODO: change this endpoint since it won't return an accessToken
    // It works but you need to refresh and login.
    const response = await axios.post('/api/user', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user, accessToken }));
  };
}

export function getUserCisco(code) {
  return async (dispatch) => {
    try {
      const url = `/api/cisco/${code}`;
      const response = await axios.post(url);
      if (response.data){
        const user = response.data;
        dispatch(slice.actions.setCiscoUser(user));
      }
    } catch (e) {
      console.log(e)
      errorDialog.title = e.response.data.error;
      errorDialog.msg = e.response.data.error_description;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function getLastUserCisco() {
  return async (dispatch) => {
    try {
      const url = '/api/user/last/cisco';
      const response = await axios.get(url);
      if (response.data) {
        console.log(response.data)
        const user = response.data;
        dispatch(slice.actions.setCiscoUser(user));
      }
    } catch (e) {
      errorDialog.title = e.response.data.error;
      errorDialog.msg = e.response.data.error_description;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function logout() {
  return async (dispatch) => {
    setSession(null, null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const menu = JSON.parse(window.localStorage.getItem('menu'));
      let [valid, id] = getToken(accessToken);
      if (valid) {
        setSession(accessToken, menu);
        const response = await axios.get(`/api/user/${id}`);
        if (!response.data.check_pass) {
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: true,
              user: response.data,
              menu: menu ? menu : []
            })
          );
        } else {
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: false,
              user: null,
              menu: []
            })
          );
        }
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
            menu: []
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
          menu: []
        })
      );
    }
  };
}

export function setConfigForUser(variables) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/api/user_config/setconfig`, {
        variables: variables
      });
      if (response.status === 200) {
        const variables = response.data;
        dispatch(slice.actions.updateUser(variables));
      }
    } catch (error) {
      //dispatch(slice.actions.hasError(error)); //hasError no existe?
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteCiscoUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/cisco_user/${id}`)
      if (response.status === 204) {
        dispatch(slice.actions.setCiscoUser({}));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.stopLoading());
    }
    dispatch(slice.actions.stopLoading());
  };
}


import { PATH_COMPLIANCE, PATH_GENERAL , PATH_NETWORK_AUDIT  } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

const ComplianceRoutes = {
  path: PATH_COMPLIANCE.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_COMPLIANCE.compliance.vulnerabilities,
      component: lazy(() => import('../views/pages/ComplianceView/Vulnerabilities/Main'))
    },
    {
      exact: true,
      path: PATH_COMPLIANCE.compliance.vulnerabilitiesBySIR,
      component: lazy(() => import('../views/pages/ComplianceView/Vulnerabilities/Main'))
    },
    {
      exact: true,
      path: PATH_COMPLIANCE.compliance.bugs,
      component: lazy(() => import('../views/pages/ComplianceView/Bugs/Main'))
    },
    {
      exact: true,
      path: PATH_COMPLIANCE.compliance.bugsBySeverity,
      component: lazy(() => import('../views/pages/ComplianceView/Bugs/Main'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default ComplianceRoutes;

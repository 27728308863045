import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  detail: '',
  wrkfList: [],
  currentWrkf: undefined
};

const slice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setWrkfList(state, action) {
      state.wrkfList = action.payload;
      state.isLoading = false;
    },

    setCurrentWrkf(state, action) {
      state.currentWrkf = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setWrkfList, setCurrentWrkf } = slice.actions;

export function getWrkfList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/workflows`);
      dispatch(slice.actions.setWrkfList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWrkfById(wrkfId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/workflow/${wrkfId}`);
      dispatch(slice.actions.setCurrentWrkf(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveWrkf(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/workflow`, body);
      callOnSubmitted(true);
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteWrkfById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/workflow/${id}`);
      dispatch(getWrkfList());
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import axios from '../..//utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  variables: {
    notify_by_whatsapp: false,
    notify_by_mail: false,
    notify_errors: false,
    notify_warnings: false,
    notify_info: false,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      const notifications = action.payload;
      state.notifications = notifications;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/notifications/search/_all_');
      dispatch(slice.actions.getNotificationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotificationsLimit(limit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/notifications/last/${limit}`);
      dispatch(slice.actions.getNotificationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAllAsRead(onComplete) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/notifications/markAllAsRead');
      onComplete();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAsRead() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/notifications/markAllAsRead/:id');
      dispatch(slice.actions.getNotificationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

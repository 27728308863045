import { Icon } from '@iconify/react';
import axios from 'axios';
import Scrollbars from '../../../../components/Scrollbars';
import NotificationItem from './NotificationItem';
import PopoverMenu from '../../../../components/PopoverMenu';
import bellFill from '@iconify-icons/eva/bell-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState, useEffect } from 'react';
import doneAllFill from '@iconify-icons/eva/done-all-fill';
import {
  markAllAsRead,
  getNotificationsLimit
} from '../../../../redux/slices/notifications';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { MIconButton } from '../../../../theme';
import { PATH_NOTIFICATION } from '../../../../routes/paths';
import FilterComponent from '../../../../custom_components/FilterComponent';
import useAuth from '../../../../hooks/useAuth';

function Notifications() {
  const history = useHistory();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const { user } = useAuth();

  const totalUnRead = notifications?.filter((item) => item.isUnRead === true)
    .length;

  useEffect(() => {
    if (user.id) {
      dispatch(getNotificationsLimit(10));
    }
  }, [dispatch, user]);

  const handleMarkAllAsRead = () => {
    const onComplete = () => {
      history.push(`${PATH_NOTIFICATION.notification.inapp}/all`);
      dispatch(getNotificationsLimit(10));
    };
    dispatch(markAllAsRead(onComplete));
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        color={isOpen ? 'primary' : 'default'}
        title="Notifications"
      >
        <FilterComponent condition={totalUnRead > 0}>
          <Badge badgeContent={' '} color="error">
            <Icon icon={bellFill} width={25} height={25} />
          </Badge>
        </FilterComponent>
        <FilterComponent condition={totalUnRead === 0}>
          <Icon icon={bellFill} width={25} height={25} />
        </FilterComponent>
      </MIconButton>

      <PopoverMenu
        width={360}
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread notifications
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Box sx={{ height: { xs: 340, sm: 'auto' } }}>
          <Scrollbars>
            <List disablePadding>
              {notifications?.slice(0, 5).map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  setOpen={setOpen}
                />
              ))}
            </List>
          </Scrollbars>
        </Box>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to={`${PATH_NOTIFICATION.notification.inapp}/all`}
          >
            View All
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Notifications;

import axios, { AxiosError } from 'axios';
import {basePath, getBp2} from '../config';

axios.defaults.baseURL = basePath.url;

const axiosInstance = axios.create({
  withCredentials: false, headers: {
    'Accept': '*/*'
  },
  // Exponer headers adicionales que necesites
  exposedHeaders: ['content-disposition']
});

// Add a request interceptor
/* axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    console.log('req.rejected ' + JSON.stringify(error));
    // Do something with request error
    return Promise.reject(error);
  }
); */
// expired token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4MjEzMzc3LCJleHAiOjE2NDgyOTk3Nzd9.XSmmmRAQJ-t6xrLVwQtXrrOMmTqJIM-zFrgNOi6mqT8
/// Add a response interceptor

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Estas tiran 401 las dejamos pasar porque son las que tiran el error de login Cisco user.
    const errorUrls = [
      '/api/asset',
      '/api/asset_offline',
      '/api/uploadfile',
      '/api/autho',
      '/api/uploadDirectory',
      '/api/asset/retry_discovery'
    ];

    if (errorUrls.some(url => error.config.url.startsWith(url))) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      window.localStorage.removeItem('menu');
      window.localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
      window.location.href = '/auth/login';
    }

    const errors403Urls = ['/api/reports/builder'];

    if (error.response.status === 403) {
      if (errors403Urls.some(url => error.config.url.startsWith(url))) {
        return Promise.reject(error);
      }
      window.localStorage.removeItem('menu');
      window.localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
      window.location.href = '/auth/login';
    }

    return Promise.reject(error);
  }
);

export const api2 = axios.create({
  ...axiosInstance.defaults,
  baseURL: getBp2().url,
});

// Interceptores para api2
api2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('API2 Error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;

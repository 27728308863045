import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  errorDetail: '',
  commentsList: [],
  currentComment: undefined,
};

const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCommentsList(state, action) {
      state.commentsList = action.payload;
      state.isLoading = false;
    },

    setCurrentComment(state, action) {
      state.currentComment = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setCommentsList, setCurrentComment } = slice.actions;

// Functions
export function getCommentsList(entityName, entityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/comments/${entityName}/${entityId}`);
      dispatch(slice.actions.setCommentsList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCommentById(commentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/tag/${commentId}`);
      dispatch(slice.actions.setCurrentComment(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveComment(body) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.post(`/api/comment`, body);
        dispatch(slice.actions.stopLoading());
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  /*
  export function updateComment(body, callOnSubmitted) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.patch(`/api/comment/${body.id}`, body);
        dispatch(slice.actions.stopLoading());
        callOnSubmitted(true);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function deleteCommentById(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.delete(`/api/comment/${id}`);
        dispatch(getTagsList())
        dispatch(slice.actions.stopLoading());
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
*/
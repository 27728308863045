import { PATH_NETWORK_AUDIT } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

const AuditRoutes = {
  path: PATH_NETWORK_AUDIT.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_NETWORK_AUDIT.audit.overview,
      component: lazy(() => import('../views/pages/Policies/Overview'))
    },
    {
      exact: true,
      path: PATH_NETWORK_AUDIT.audit.policies,
      component: lazy(() => import('../views/pages/Policies/PoliciesView/Main'))
    },
    {
      exact: true,
      path: PATH_NETWORK_AUDIT.audit.policy,
      component: lazy(() =>
        import('../views/pages/Policies/PoliciesView/Detail')
      )
    },
    {
      exact: true,
      path: PATH_NETWORK_AUDIT.audit.policyGroups,
      component: lazy(() => import('../views/pages/Policies/GroupView/Main'))
    },
    {
      exact: true,
      path: PATH_NETWORK_AUDIT.audit.policyGroup,
      component: lazy(() => import('../views/pages/Policies/GroupView/Detail'))
    },
    {
      exact: true,
      path: PATH_NETWORK_AUDIT.audit.bulk_upload,
      component: lazy(() => import('../views/pages/Policies/PoliciesView/BulkLoad'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AuditRoutes;

import { PATH_NOTIFICATION } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

// ----------------------------------------------------------------------

const NotificationRoutes = {
  path: PATH_NOTIFICATION.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_NOTIFICATION.notification.inapp,
      component: lazy(() => import('../views/pages/MailView'))
    },
    {
      exact: true,
      path: PATH_NOTIFICATION.notification.labels,
      component: lazy(() => import('../views/pages/MailView'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default NotificationRoutes;

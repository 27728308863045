import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.main,
      component: lazy(() => import('../views/general/DashboardAppView/Main'))
    },
    {
      exact: true,
      path: PATH_APP.general.alerts,
      component: lazy(() => import('../views/general/DashboardAppView/Alerts'))
    },
    {
      exact: true,
      path: PATH_APP.general.lifecycle,
      component: lazy(() => import('../views/general/DashboardAppView/Lifecycle'))
    },
    {
      exact: true,
      path: PATH_APP.general.remediation,
      component: lazy(() => import('../views/general/DashboardAppView/Remediation'))
    },
    {
      exact: true,
      path: PATH_APP.general.snmp,
      component: lazy(() => import('../views/general/DashboardAppView/SNMP'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.main} />
    },
    {
      exact: true,
      path: PATH_APP.general.notifications,
      component: lazy(() => import('../views/pages/MailView'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;

import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { ErrorDialog, startDialog, closeDialog } from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  currentComponent: undefined,
  notifications: null,
  componentsList: [],
  componentsToCompare: [],
  idSelected: -1,
  changed: 'none',
  pi: 3000
};

// facts { id y status , lo de mas lo saca del config}

const slice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetails = action.payload;
    },

    // RESET ERROR
    resetError(state) {
      state.isLoading = false;
      state.error = false;
      state.errorDetails = {};
    },

    onChangeStatus(state, action) {
      state.changed = action.payload;
    },
    setComponentsToCompare(state, action) {
      state.isLoading = false;
      state.componentsToCompare = action.payload;
    },
    setComponentList(state, action) {
      state.isLoading = false;
      state.componentsList = action.payload;
    },

    setComponent(state, action) {
      state.isLoading = false;
      state.currentComponent = action.payload;
    },

    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },

    stopLoading(state) {
      state.isLoading = false;
    },
    setPi(state, action) {
      state.pi = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  stopLoading,
  setComponent,
  setDiscovery,
  setIdSelected,
  onChangeStatus,
  setComponentList,
  setStatus,
  setPi
} = slice.actions;

export const configStatus = {
  DISCOVERING: 'DISCOVERING',
  ACTIVE: 'ACTIVE',
  NEW: 'NEW'
};

export function getComponentsList(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/components/search/_all_`, body);
      dispatch(slice.actions.setComponentsToCompare(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComponentById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/component/${id}`);
      dispatch(slice.actions.setComponent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLastComponentsByAssetId(assetId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `api/components/asset/${assetId}/last/20`
      );
      dispatch(slice.actions.setComponentList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComponentToCompare(id, id2) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/component/compare/${id}/to/${id2}`);
      dispatch(slice.actions.setComponentsToCompare(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveConfig(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/component`, body);
      dispatch(slice.actions.onChangeStatus('created'));
      callOnSubmitted(true);
    } catch (error) {
      if (error.response?.status === 403) {
        let ed = new ErrorDialog(
          'Error discovering Config, check credentials and network address.',
          'Error creating Config',
          'Ok'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
      }
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function patchConfig(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.patch(`/api/component/${data.id}`, data);
      dispatch(slice.actions.onChangeStatus('updated'));
      dispatch(slice.actions.setComponent(resp.data));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function getConfigById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/config/${id}`);
      dispatch(slice.actions.setComponent(resp.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import {PATH_ASSETS, PATH_OFFLINE_ASSETS} from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from '../layouts/DashboardLayout';
import AuthProtect from '../components/Auth/AuthProtect';

// ----------------------------------------------------------------------

const InventoryRoutes = {
  path: PATH_ASSETS.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    {
      exact: true,
      path: PATH_ASSETS.general.create,
      component: lazy(() => import('../views/pages/InventoryView/CreateView'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.stock,
      component: lazy(() => import('../views/pages/InventoryView/StockCreateView'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.search,
      component: lazy(() => import('../views/pages/InventoryView/SearchView'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.discovery,
      component: lazy(() =>
        import('../views/pages/InventoryView/DiscoveryView')
      )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.run,
      component: lazy(() => import('../views/pages/TaskView/Main'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.bulk_upload,
      component: lazy(() => import('../views/pages/InventoryView/BulkLoad'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.config_detail,
      component: lazy(() =>
        import('../views/pages/InventoryView/ConfigView/DetailsView')
      )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.configs,
      component: lazy(() =>
        import('../views/pages/InventoryView/ConfigView/Main')
      )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.config_compare,
      component: lazy(() =>
        import('../views/pages/InventoryView/ConfigView/CompareView')
      )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.component,
      component: lazy(() =>
        import('../views/pages/InventoryView/ComponentView/Main')
      )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.component_detail,
      component: lazy(() =>
      import('../views/pages/InventoryView/ComponentView/DetailsView')
    )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.component_compare,
      component: lazy(() =>
      import('../views/pages/InventoryView/ComponentView/CompareView')
    )
    },
    {
      exact: true,
      path: PATH_ASSETS.general.files_by_upload,
      component: lazy(() => import('../views/pages/Reports/Impl/files_by_upload'))
    },
    /* Tags */
    {
      exact: true,
      path: PATH_ASSETS.general.tags,
      component: lazy(() => import('../views/pages/TagsView/Main'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.tag_detail,
      component: lazy(() => import('../views/pages/TagsView/CreateView/FormView'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.smart_account,
      component: lazy(() => import('../views/pages/SmartAccountView/Main'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.discovery_subnet,
      component: lazy(() => import('../views/pages/InventoryView/SubnetDiscovery'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.scan_detail,
      component: lazy(() => import('../views/pages/InventoryView/SubnetDiscovery/Detail'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.targets,
      component: lazy(() => import('../views/pages/TargetsView/Main'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.new_target,
      component: lazy(() => import('../views/pages/TargetsView/CreateView/FormView'))
    },
    {
      exact: true,
      path: PATH_ASSETS.general.target_detail,
      component: lazy(() => import('../views/pages/TargetsView/CreateView/FormView'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default InventoryRoutes;

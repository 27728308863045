import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  getMainData: [],
  bugsByDeviceType: [],
  vulnerabilitiesByDeviceType: []
};

// facts { id y status , lo de mas lo saca del asset}

const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetails = action.payload;
    },

    // RESET ERROR
    resetError(state) {
      state.isLoading = false;
      state.error = false;
      state.errorDetails = {};
    },

    setMainData(state, action) {
      state.isLoading = false;
      state.mainData = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setVulnerabilitiesByDeviceType(state, action) {
      state.isLoading = false;
      state.vulnerabilitiesByDeviceType = action.payload;
    },
    setBugsByDeviceType(state, action) {
      state.isLoading = false;
      state.bugsByDeviceType = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetError, startLoading, stopLoading } = slice.actions;

export function getMainData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/main`);
      dispatch(slice.actions.setMainData(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVulnerabilitiesByType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/vulnerabilities/device`);
      dispatch(slice.actions.setVulnerabilitiesByDeviceType(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBugsByType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/bugs/device`);
      dispatch(slice.actions.setBugsByDeviceType(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

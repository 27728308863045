import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { Icon } from '@iconify/react';
import { formatDistanceToNow } from 'date-fns';
import clockFill from '@iconify-icons/eva/clock-fill';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { MAvatar } from '../../../../theme';

import {
  Box,
  Avatar,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';
import { PATH_NOTIFICATION } from '../../../../routes/paths';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    // padding: theme.spacing(0, 2),
    padding: theme.spacing(1.5, 2.5),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    },
    '&:hover': {
      zIndex: 999,
      position: 'relative',
      boxShadow: theme.shadows[25].z24,
      '& $actions': { opacity: 1 }
    },
    '&:not(:last-child)': {
      marginBottom: 1
    }
  },
  wrap: {
    minWidth: 0,
    display: 'flex',
    padding: theme.spacing(2, 0),
    transition: theme.transitions.create('padding')
  },
  name: {
    minWidth: 200,
    paddingRight: theme.spacing(2)
  },
  date: {
    flexShrink: 0,
    minWidth: 120,
    textAlign: 'right'
  },
  unread: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '& $name': { fontWeight: theme.typography.fontWeightBold },
    '& $date': { fontWeight: theme.typography.fontWeightBold },
    '& $subject': { fontWeight: theme.typography.fontWeightBold },
    '& $message': { color: theme.palette.text.secondary }
  },
  isSelected: { backgroundColor: theme.palette.action.selected },
  isDense: { padding: theme.spacing(1, 0) },
  subject: {},
  message: {},
  actions: {}
}));


function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.subject}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp; {noCase(notification.message)}
      </Typography>
    </Typography>
  );
  return {
    title: title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  className: PropTypes.string
};

function NotificationItem({ notification, className, setOpen }) {
  const classes = useStyles();
  const title = renderContent(notification).title;

  return (
    <ListItem
      button
      to={`${PATH_NOTIFICATION.notification.inapp}/all/${notification.id}`}
      disableGutters
      onClick={() => setOpen(false)}
      key={notification.id}
      component={RouterLink}
      className={clsx(
        classes.root,
        {
          [classes.isUnRead]: notification.isUnRead
        },
        className
      )}
    >
      <ListItemAvatar>
        <MAvatar
          alt={"Processor"}
          src={'/static/icons/navbar/ic_notification.svg'}
          color={'primary'}
          sx={{ width: 32, height: 32 }}
        >
        </MAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default NotificationItem;

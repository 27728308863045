import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useSettings from '../hooks/useSettings';

Logo.propTypes = {
  className: PropTypes.string
};

function Logo({ className, ...other }) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  let logo = 'logo_blanco.svg';
  if (isLight) {
    logo = 'logo_azul.svg';
  }
  return (
    <Box
      component="img"
      alt="logo"
      src={`/static/brand/${logo}`}
      height={40}
      className={className}
      {...other}
    />
  );
}

export default Logo;

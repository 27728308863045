import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  nciList: [],
  crListByStatus: [],
  nciClosedList: [],
  crOpenClosedList: [],
  nciOpenClosedList: []
};

// facts { id y status , lo de mas lo saca del asset}

const slice = createSlice({
  name: 'remediation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetails = action.payload;
    },

    // RESET ERROR
    resetError(state) {
      state.isLoading = false;
      state.error = false;
      state.errorDetails = {};
    },
    setNCIList(state, action) {
      state.isLoading = false;
      state.nciList = action.payload;
    },
    setCRListByStatus(state, action) {
      state.isLoading = false;
      state.crListByStatus = action.payload;
    },
    setNCIClosedList(state, action) {
      state.isLoading = false;
      state.nciClosedList = action.payload;
    },
    setNCIOpenClosedList(state, action) {
      state.isLoading = false;
      state.nciOpenClosedList = action.payload;
    },
    setCROpenClosedList(state, action) {
      state.isLoading = false;
      state.crOpenClosedList = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetError, startLoading, stopLoading } = slice.actions;

export function getNCIList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/remediation/ncilist`);
      dispatch(slice.actions.setNCIList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNCIClosedList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(
        `/api/dashboard/remediation/nciclosedlist`
      );
      dispatch(slice.actions.setNCIClosedList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNCIOpenClosedList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(
        `/api/dashboard/remediation/nciopenclosedlist`
      );
      dispatch(slice.actions.setNCIOpenClosedList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCROpenClosedList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(
        `/api/dashboard/remediation/cropenclosedlist`
      );
      dispatch(slice.actions.setCROpenClosedList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

const calculateOrder = (label) => {
  switch (label) {
    case 'OPEN':
      return 1;
    case 'DRAFT':
      return 2;
    case 'PENDING':
      return 3;
    case 'WIP':
      return 4;
    case 'CLOSED':
      return 5;
    default:
      return 100;
  }
};

export function getCRListByStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/remediation/crstatus`);
      if (result?.data?.data.length > 0) {
        const data = [];
        for (let item of result?.data?.data) {
          const key = Object.keys(item)[0];
          data.push({
            x: key,
            y: Number(item[key]),
            o: calculateOrder(key)
          });
        }

        const res = data.sort(function (a, b) {
          if (a.o > b.crOpenClosedList) {
            return 1;
          }
          if (a.o < b.o) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        const chartData = [{ data: res }];
        dispatch(slice.actions.setCRListByStatus(chartData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

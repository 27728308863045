import axios from '../../utils/axios';
import AxiosError from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { ErrorDialog, startDialog, closeDialog } from './errorDialog';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  detail: '',
  walkData: {},
  traps: [],
  asset: undefined
};

const slice = createSlice({
  name: 'snmp',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setWalkData(state, action) {
      state.isLoading = false;
      state.walkData = action.payload;    
    },

    setTraps(state, action) {
      state.isLoading = false;
      state.traps = action.payload;
    },

    setAsset(state, action) {
      state.isLoading = false
      state.asset = action.payload;
    }

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setWalkData,
  setAsset
} = slice.actions;

export function getWalk() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/snmp/walk`);
      dispatch(slice.actions.setWalkData(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTraps() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/snmp/traps`);
      dispatch(slice.actions.setTraps(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import axios, {api2} from '../../utils/axios';
import {createSlice} from '@reduxjs/toolkit';
import {startDialog, ErrorDialog, closeDialog} from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  currentChangeRequest: undefined,
  notifications: null,
  changeRequestList: [],
  idSelected: -1,
  query: '',
  status: 'all',
  asset: undefined,
  showDownloadModal: false,
  fileDownloaded: false,
  SwUpdate: undefined,
  timePeriod: 'all'
};

const slice = createSlice({
  name: 'change_request',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },

    // RESET ERROR
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    setChangeRequest(state, action) {
      state.isLoading = false;
      state.currentChangeRequest = action.payload;
    },

    setShowDownloadModal(state, action) {
      state.isLoading = false;
      state.showDownloadModal = action.payload;
    },
    setFileDownloaded(state, action) {
      state.fileDownloaded = action.payload;
    },
    setSwUpdate(state, action) {
      state.isLoading = false;
      state.SwUpdate = action.payload;
    },

    setMine(state, action) {
      state.mine = action.payload;
    },

    setQuery(state, action) {
      state.query = action.payload;
    },

    setAsset(state, action) {
      state.asset = action.payload;
    },

    setStatus(state, action) {
      state.status = action.payload;
    },

    setChangeRequestList(state, action) {
      state.isLoading = false;
      state.changeRequestList = action.payload;
    },

    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    setQueuedOk(state, action) {
      state.queuedOk = action.payload;
    },

    onChangeStatus(state, action) {
      state.status = action.payload;
      state.isLoading = false;
    },

    setTimePeriod(state, action) {
      state.timePeriod = action.payload;
    }
  }
});

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  setIdSelected,
  setChangeRequest,
  setMine,
  setAsset,
  setQuery,
  setStatus,
  setShowDownloadModal,
  setQueuedOk,
  setSwUpdate,
  setTimePeriod
} = slice.actions;

// DEFAULTS

export function deleteChangeRequest(id) {
  return async (dispatch) => {

    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/change_request/${id}`);
      dispatch(getChangeRequestsList(null));
      dispatch(slice.actions.onChangeStatus('deleted'));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(startDialog(new ErrorDialog('Error deleting Change Request.')));
    }
  };
}

export function getChangeRequestsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/change_requests/_all_`);
      dispatch(slice.actions.setChangeRequestList(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function searchChangeRequestsList(query, asset_id, status, timePeriod) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    let p = {}
    if (asset_id) p.asset_id = asset_id
    if (status) p.status = status
    if (timePeriod) p.time_period = timePeriod

    try {
      const response = await axios.get(`/api/change_requests/${query ? query : '_all_'}`, {params: p});
      const list = response.data.data;
      if (response.data.data.length > 0 && status !== 'all') {
        dispatch(slice.actions.setChangeRequestList(list));
      } else {
        dispatch(slice.actions.setChangeRequestList(list));
      }

      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getChangeRequestsListByAsset(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/change_requests/asset/${id}`);
      dispatch(slice.actions.setChangeRequestList(response.data.query.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function downloadOs(sid, aId, crId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/software/download`, {
        s_id: sid,
        a_id: aId,
        cr_id: crId
      });
      dispatch(slice.actions.setQueuedOk(response.status === 204));
      dispatch(slice.actions.stopLoading());
      dispatch(getChangeRequestById(crId));
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setQueuedOk(false));
    }
    dispatch(slice.actions.setShowDownloadModal(true));
  };
}

export function getChangeRequestById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/change_request/${id}`);
      const cr = response.data;
      dispatch(slice.actions.setChangeRequest(cr));
      if (cr?.alternatives) {
        for (let index = 0; index < cr.alternatives.length; index++) {
          const sw = cr.alternatives[index];
          if (sw.binName === cr.binaryToUpdate) {
            dispatch(slice.actions.setSwUpdate(sw));
            break;
          }
        }
      }
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      errorDialog.msg = `Error finding Change Request with id: ${id}.`;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function saveChangeRequest(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/change_request`, body);
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
    } catch (error) {
      callOnSubmitted(false);
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function validateBinaryIsDownloaded(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setFileDownloaded(false));
    try {
      await api2.get(`/api/check_binary_in_tftp/${name}`);
      dispatch(slice.actions.setFileDownloaded(true));
    } catch (error) {
      dispatch(slice.actions.setFileDownloaded(false));
    }
    dispatch(slice.actions.stopLoading());
  };
}


export function changeStatus(data, action, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/change_request/apply/${action}/${data.id}`, data);
      dispatch(slice.actions.setChangeRequest(undefined));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
    dispatch(slice.actions.stopLoading());
  };
}

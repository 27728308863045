import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  swList: [],
  vulnerabilityList: [],
  PViolations: [],
  bugsList: [],
};

// facts { id y status , lo de mas lo saca del asset}

const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetails = action.payload;
    },

    // RESET ERROR
    resetError(state) {
      state.isLoading = false;
      state.error = false;
      state.errorDetails = {};
    },
    setSwList(state, action) {
      state.isLoading = false;
      state.swList = action.payload;
    },
    setVulnerabilitiesList(state, action) {
      state.isLoading = false;
      state.vulnerabilityList = action.payload;
    },
    setBugsList(state, action) {
      state.isLoading = false;
      state.bugsList = action.payload;
    },
    setPViolations(state, action) {
      state.isLoading = false;
      state.PViolations = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  stopLoading,
} = slice.actions;


export function getSwList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/alerts/swlist`);
      dispatch(slice.actions.setSwList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPViolations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/alerts/pviolations`);
      dispatch(slice.actions.setPViolations(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBugsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/alerts/bugslist`);
      dispatch(slice.actions.setBugsList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVulnerabilities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/dashboard/alerts/vulnerabilitieslist`);
      dispatch(slice.actions.setVulnerabilitiesList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  errorDetail: {},
  report_definitions: [], // list of available report definitions (minify version, just id, name and description).
  report_definition: {} // report definition. Id, Name, filtes, fields, etc.
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setError(state, action) {
      const errorDescription = action.payload;
      state.errorDetail = errorDescription;
    },
    onChangeStatus(state, action) {
      state.changed = action.payload;
    },
    setReportDefinitions(state, action) {
      state.report_definitions = action.payload;
    },
    setReportDefinition(state, action) {
      state.report_definition = action.payload;
    },
    deleteReportDefinition(state, action) {
      state.report_definitions = state.report_definitions.filter(
        (report) => report.id !== Number(action.payload)
      );
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setReportDefinition } = slice.actions;

export function getAllReport({ reportType }) {
  return async (dispatch) => {
    axios
      .get('/api/reports', {
        params: {
          reportType: reportType
        }
      })
      .then((res) => {
        const updatedReportDefinitions = res.data.data.map((report) => ({
          ...report,
          disableDelete: report.type === 'built-in' ? true : false
        }));

        dispatch(slice.actions.setReportDefinitions(updatedReportDefinitions));
      })
      .catch((error) => {
        console.log(error);
        // TODO: handle errors.
      });
  };
}

// Helper function to fetch the report data
export const fetchReport = async ({ reportIdOrName }) => {
  try {
    const res = await axios.get(`/api/reports/${reportIdOrName}`);
    return res.data.data;
  } catch (error) {
    throw error;
  }
};

// Redux action that dispatches and stores the data in the slice
export const getReport = ({ reportIdOrName }) => async (dispatch) => {
  try {
    const data = await fetchReport({reportIdOrName});
    dispatch(slice.actions.setReportDefinition(data));
    return await Promise.resolve();
  } catch (error) {
    return await Promise.reject(error.response.status);
  }
};

export function deleteReportById(reportId) {
  return async (dispatch) => {
    axios
      .delete(`/api/reports/${reportId}`)
      .then((res) => {
        dispatch(slice.actions.deleteReportDefinition(reportId));
      })
      .catch((error) => {
        console.log(error);
        // TODO: handle errors.
      });
  };
}

export const createReport = (
  name,
  description,
  entity,
  fields,
  filters
) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/reports/builder`, {
      name,
      description,
      entity,
      fields,
      filters
    });
    // Wrap dispatch in a Promise-based function
    return Promise.resolve(
      dispatch(slice.actions.setReportDefinition(response.data.data))
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return Promise.resolve(); // Reject if there’s an error
  }
};

export const updateReport = (
  id,
  name,
  description,
  entity,
  fields,
  filters
) => async (dispatch) => {
  try {
    const response = await axios.put(`/api/reports/builder`, {
      id,
      name,
      description,
      entity,
      fields,
      filters
    });
    // Handle successful creation
    dispatch(slice.actions.setError(''));
    dispatch(slice.actions.setReportDefinition(response.data.data));

    return response.data;
  } catch (error) {
    let errorDescription = '';

    switch (error.response.status) {
      case 403:
        errorDescription = `Updating a built-in report definition is not permitted. Change the report definition name and one of the properties to create a new custom report using this built-in report as a template.`;
        break;

      case 404:
        errorDescription = `The report definition was not found.`;
        break;

      case 409:
        if ('DuplicateReportNameError' === error.response.data.error) {
          errorDescription = `There is another report definition with the same name.`;
        } else {
          errorDescription = `There is another report definition with the same root entity, fields and filters.`;
        }

        break;

      default:
        errorDescription = 'Unexpected status code';
        break;
    }

    dispatch(slice.actions.setError(errorDescription));
    return Promise.reject(new Error(errorDescription));
  }
};

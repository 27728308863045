import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';
import AxiosError from 'axios';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  status: '',
  currentRepository: undefined,
  notifications: null,
  repositoryList: [],
  idSelected: -1
};

const slice = createSlice({
  name: 'repository',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },

    // RESET ERROR
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    // SET SAVED ASSET
    setRepository(state, action) {
      state.isLoading = false;
      state.currentRepository = action.payload;
    },

    setRepositoryList(state, action) {
      state.isLoading = false;
      state.repositoryList = action.payload;
    },

    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    onChangeStatus(state, action) {
      state.status = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetError, startLoading, setIdSelected, setRepository } = slice.actions;

// DEFAULTS

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

export function deleteRepository(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle response.
      await axios.delete(`/api/repository/${id}`);
      dispatch(getRepositorysList());
      dispatch(slice.actions.onChangeStatus('borrado'));
    } catch (error) {

      errorDialog.title = 'Error deleting a repository';
      if ( error?.response?.data?.message.indexOf("FOREIGN KEY (`credentialName`)") !== -1 ) {
        errorDialog.msg = 'There are assets using this credentials. Please delete them first.';
      }else {
        errorDialog.title = 'error deleting a repository, contact the administrator';
      }

      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function getRepositoryByName(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/repository/${id}`);
      dispatch(slice.actions.setRepository(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRepositorysList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/repositories/search/_all_`);
      dispatch(slice.actions.setRepositoryList(response.data.data));
    } catch (error) {
      errorDialog.msg = 'Error creating or updating a repository';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}


export function updateRepository(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle the response.
      await axios.patch(`/api/repository/${body.id}`, body);
      callOnSubmitted(true);
    } catch (error) {
      let status;
      if (error instanceof AxiosError) {
        status = error.response.status;
      } else {
        status = error.response?.status;
      }
      if (status === 409) {
        errorDialog.title = 'Error updating a repository';
        errorDialog.msg = 'Repository name already exists, enter a different name.';
        errorDialog.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(errorDialog));
      }else {
        errorDialog.title = 'Error';
        errorDialog.msg = 'Error creating or updating a repository';
        errorDialog.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(errorDialog));
      }

      callOnSubmitted(false);
    }
  };
}

export function saveRepository(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle the response.
      await axios.post(`/api/repository`, body);
      callOnSubmitted(true);
    } catch (error) {
      let status;
      if (error instanceof AxiosError) {
        status = error.response.status;
      } else {
        status = error.response?.status;
      }
      if (status === 409) {
        errorDialog.title = 'Error creating or updating a repository';
        errorDialog.msg = 'Repository name already exists, enter a different name.';
        errorDialog.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(errorDialog));
      }else {
        errorDialog.title = 'Error';
        errorDialog.msg = 'Error creating or updating a repository';
        errorDialog.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(errorDialog));
      }

      callOnSubmitted(false);
    }
  };
}

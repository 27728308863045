import { PATH_TASKS} from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

// ----------------------------------------------------------------------

const TasksRoutes = {
  path: PATH_TASKS.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_TASKS.general.jobs,
      component: lazy(() => import('../views/pages/NTaskView/Main'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.detail,
      component: lazy(() => import('../views/pages/NTaskView/DetailView'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.templates,
      component: lazy(() => import('../views/pages/TemplateView/Main'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.template,
      component: lazy(() => import('../views/pages/TemplateView/Detail'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.create,
      component: lazy(() => import('../views/pages/NTaskView/DetailView'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.create_template_pol,
      component: lazy(() => import('../views/pages/TemplateView/Detail'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.workflow,
      component: lazy(() => import('../views/pages/Workflows'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.workflow_create,
      component: lazy(() => import('../views/pages/Workflows/Detail'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.workflow_detail,
      component: lazy(() => import('../views/pages/Workflows/Detail'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.main,
      component: lazy(() => import('../views/pages/AutomationDashView/Main'))
    },
    {
      exact: true,
      path: PATH_TASKS.general.schedules,
      component: lazy(() => import('../views/pages/SchedulesView/Main'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default TasksRoutes;

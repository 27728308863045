import axios from '../../utils/axios';
import AxiosError from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { ErrorDialog, startDialog, closeDialog } from './errorDialog';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  file: {},
  detail: '',
  fileList: [],
  runList: [],
  idSelected: null
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setRunList(state, action) {
      state.isLoading = false;
      state.runList = action.payload;
    },

    setFileList(state, action) {
      state.isLoading = false;
      state.fileList = action.payload.data;
      state.detail = action.payload.detail;
    },
    
    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setRunList,
  setFileList,
  setIdSelected
} = slice.actions;

export function getBcsUploads() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/bcs/uploads`);
      dispatch(slice.actions.setRunList(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUploads() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/uploads`);
      if (result?.data) {
        dispatch(slice.actions.setRunList(result.data));
      } else {
        dispatch(slice.actions.setRunList([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteUploadById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/upload/${id}`);
      dispatch(getUploads());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUploadById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/files/upload/${id}`);
      dispatch(slice.actions.setFileList(result.data));
    }
     catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadFile(file, path, onUploadFinished, onFailCredentials = null, onNoAgent = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('inventoryFile', file, `${file.name}`);
      const response = await axios.post('/api/uploadfile', formData);
      onUploadFinished(response.status >= 200);
    } catch (error) {
      let status;
      if (error instanceof AxiosError) {
        status = error.response.status;
      } else {
        status = error.response?.status;
      }
      if (status === 401) {
        let ed = new ErrorDialog(
          "Please update your Cisco account. If you do not have a Cisco account, " +
               "mark all Cisco assets in the file with the flag 'off' to create them without one.",
          'Attention',
          'Update',
          'Close'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
          onFailCredentials();
        };
        ed.cancelBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(ed));
      }
      if (status === 406) {
        let ed = new ErrorDialog(
          'No agent available, please set up one.',
          'Error uploading file',
          'Ok'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
          if (onNoAgent) {
            onNoAgent();
          }
        };
        dispatch(startDialog(ed));
      }
      onUploadFinished(false);
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function uploadBCSFile(file, path, onUploadFinished, onFailCredentials) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('inventoryFile', file, `${file.name}`);
      const response = await axios.post('/api/bcs/uploadfile', formData);
      onUploadFinished(response.status === 201);
    } catch (error) {
      let status;
      if (error instanceof AxiosError) {
        status = error.response.status;
      } else {
        status = error.response?.status;
      }
      if (status === 401) {
        let ed = new ErrorDialog(
          'Please update your Cisco user',
          'Error creating Asset',
          'Update'
        );
        ed.acceptBtnClickFn = () => {
          dispatch(closeDialog());
          onFailCredentials();
        };
        dispatch(startDialog(ed));
      }
      onUploadFinished(false);
      console.log('error >>', error);
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

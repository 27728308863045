import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useSettings from '../hooks/useSettings';

LogoIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number
};

function LogoIcon({ className, height = 40 ,...other }) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  let logo = 'icono_blanco.svg';
  if (isLight) {
    logo = 'icono_azul.svg';
  }
  return (
    <Box
      component="img"
      alt="Icon"
      src={`/static/brand/${logo}`}
      height={height}
      className={className}
      {...other}
    />
  );
}

export default LogoIcon;

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: {
    licenses: false,
    v_accounts: false
  },
  licensesList: [],
  virtualAccounts: [],
  selectedVAccount: 'all',
  total: 0, // El total de todos los registros
  subtotal: 0, // El total de la busqueda sin paginar
  query: '_all_',
  responseError: undefined,
  selectedStatus: '_all_',
  selectedType: '_all_'
};

const slice = createSlice({
  name: 'smartAccount',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    stopLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setLicensesList(state, action) {
      state.licensesList = action.payload;
      state.isLoading.licenses = false;
    },
    setVirtualAccounts(state, action) {
      state.virtualAccounts = action.payload;
      state.isLoading.v_accounts = false;
    },
    setSelectedVAccount(state, action) {
      state.selectedVAccount = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setSubtotal(state, action) {
      state.subtotal = action.payload;
    },
    setResponseError(state, action) {
      state.responseError = action.payload;
    },
    setSelectedStatus(state, action) {
      state.selectedStatus = action.payload;
    },
    setSelectedType(state, action) {
      state.selectedType = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedVAccount, setQuery, setSubtotal, setResponseError, setSelectedStatus, setSelectedType } = slice.actions;

export function getLicences(selectedVAccount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading('licenses'));
    try {
      const params = selectedVAccount ? { vAccount: selectedVAccount } : {};
      const response = await axios.get('/api/smart_account/licences', { params });
      
      const { licenses = [], totalRecords = 0 } = response.data;
      dispatch(slice.actions.setLicensesList(licenses));
      dispatch(slice.actions.setTotal(totalRecords));
    } catch (error) {
      console.error('Error fetching licenses:', error);
      if (error.response && error.response.status === 422) {
      // TODO: Agregar el caso 401 si la API de Cisco responde con malas credenciales,
      // y mostrarlo en front.
        dispatch(slice.actions.setLicensesList([]));
        dispatch(slice.actions.setResponseError('Missing Information'));
      } else {
        dispatch(slice.actions.setLicensesList([]));
        dispatch(slice.actions.setResponseError('An error occurred while fetching licenses.'));
      }
    } finally {
      dispatch(slice.actions.stopLoading('licenses'));
    }
  };
}

export function getVirtualAccounts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading('v_accounts'));
    try {
      const response = await axios.get('/api/smart_account/v_accounts');
      
      if (response.data?.virtualAccounts) {
        dispatch(slice.actions.setVirtualAccounts(response.data.virtualAccounts));
      } else {
        dispatch(slice.actions.setVirtualAccounts([]));
      }
    } catch (error) {
      console.error('Error fetching virtual accounts:', error);
      // TODO: Agregar el caso 401 si la API de Cisco responde con malas credenciales,
      // y mostrarlo en front.
      if (error.response && error.response.status === 422) {
        dispatch(slice.actions.setResponseError('Missing Information'));
      } else {
        dispatch(slice.actions.setResponseError('An error occurred while fetching virtual accounts.'));
      }
      dispatch(slice.actions.setVirtualAccounts([]));
    } finally {
      dispatch(slice.actions.stopLoading('v_accounts'));
    }
  };
}
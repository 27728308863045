import React from 'react';
import Label from './Label';
import PropTypes from 'prop-types';

Chip.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
};

export default function Chip({ name, color }) {
    let c = color ? color : 'info';
    return (
        <Label variant={'filled'} color={c}  >
            {name}
        </Label>
    );
}
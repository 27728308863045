import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetail: {},
  changed: 'none',
  editingUser: null,
  userList: [],
  notifications: null,
  roleList: [],
  selectedRole: 'all'
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    onChangeStatus(state, action) {
      state.isLoading = false;
      state.changed = action.payload;
    },
    setUserToEdit(state, action) {
      state.editingUser = action.payload;
    },
    // setErrorDetail
    setErrorDetail(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    // RESET ERROR
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },
    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    getRoleListSuccess(state, action) {
      state.isLoading = false;
      state.roleList = action.payload;
    },
    // CREATE USER
    stopLoading(state) {
      state.isLoading = false;
    },
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onChangeStatus, setUserToEdit, setSelectedRole } = slice.actions;

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

export function getNotifications(data) {
  return async (dispatch) => {};
}

export function getProfile(data) {
  return async (dispatch) => {};
}

export function deleteUser(userId, selectedRole) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/user/${userId}`);
      dispatch(getUserList(null, selectedRole));
      dispatch(slice.actions.onChangeStatus('deleted'));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(error));
    }
  };
}

export function createUser(user, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/user/', user);
      // console.log(`response ${JSON.stringify(response)}`);
      dispatch(slice.actions.onChangeStatus('created'));
      dispatch(slice.actions.stopLoading());

      if (response?.data?.error) {
        const msg = response?.data?.error;
        dispatch(slice.actions.stopLoading());
        dispatch(slice.actions.setErrorDetail(msg));
        errorDialog.msg = msg;
        errorDialog.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(errorDialog));
        callOnSubmitted(false);
      } else {
        callOnSubmitted(true);
      }

    } catch (error) {
      const msg = error.response.data.error;
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(msg));
      errorDialog.msg = msg;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
      callOnSubmitted(false);
    }
  };
}

export function updateUser(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/user/${data.id}`, data);
      dispatch(slice.actions.onChangeStatus('updated'));
      dispatch(slice.actions.setUserToEdit(null));
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(error));
      callOnSubmitted(false);
    }
  };
}

export function changePassword(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const r = await axios.post(`/api/user/pass/chg`, data);
      dispatch(slice.actions.stopLoading());
      if (r?.data?.error) {
        const msg = r?.data?.error;
        dispatch(slice.actions.stopLoading());
        dispatch(slice.actions.setErrorDetail(msg));
        errorDialog.msg = msg;
        errorDialog.acceptBtnClickFn = () => {
          dispatch(closeDialog());
        };
        dispatch(startDialog(errorDialog));
        callOnSubmitted(false);
      } else {
        callOnSubmitted(true);
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(error));
      callOnSubmitted(false);
    }
  };
}

export function getUserList(key, role) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {}
      if (role) p.role = role;
      let url = `/api/user/search/${key}`;
      if (key === null || key.toString().trim() === '') {
        url = '/api/user/search/_all_';
      }
      const response = await axios.get(url, {params: p});
      dispatch(slice.actions.getUserListSuccess(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(error.response));
    }
  };
}

export function getRoleList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/roles/search/_all_';
      const response = await axios.get(url);
      dispatch(slice.actions.getRoleListSuccess(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(error.response));
    }
  };
}

export function getUsersByRole(role) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/users/role/${role.role}`;
      const response = await axios.get(url);
      dispatch(slice.actions.getUserListSuccess(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setErrorDetail(error.response));
    }
  };
}

export function getUserById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/api/user/${id}`;
      const response = await axios.get(url);
      dispatch(slice.actions.setUserToEdit(response.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      errorDialog.msg = `Error checking for user ${id}`;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

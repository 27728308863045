import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  themeMode: 'light',
  themeDirection: 'ltr',
  variables: {}
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    updateSettings(state, action) {
      state[action.payload.key] = action.payload;
    },

    switchMode(state, action) {
      state.themeMode = action.payload;
    },

    switchDirection(state, action) {
      state.themeDirection = action.payload;
    },

    getSettingsSuccess(state, action) {
      state.variables = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { switchMode, switchDirection } = slice.actions;

export function setSettings(variables) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // aca voy a hacer las 2 cosas juntas entonces mando post.
      const response = await axios.post(`/api/setting`, { variables });
      if (response.status === 201) {
        const variables = response.data;
        dispatch(slice.actions.updateSettings(variables.data));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getSettings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/settings`);
      const variables = response;
      let vars = {}
      for( var s of variables.data) {
        vars[s.key] = s;
      }
      dispatch(slice.actions.getSettingsSuccess(vars));
    } catch (error) {
      console.log(error);
    }
    dispatch(slice.actions.stopLoading());
  };
}


import axios, {api2} from '../../utils/axios';
import {createSlice} from '@reduxjs/toolkit';
import {startDialog, closeDialog, ErrorDialog} from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  currentIssue: undefined,
  notifications: null,
  issueList: [],
  query: '',
  idSelected: -1,
  status: 'all',
  asset: undefined,
  type: 'all',
  total: 0,
  timePeriod: 'all', // Agregamos el estado para los archivos
  files: [],
  uploadProgress: 0
};

const slice = createSlice({
  name: 'issue', initialState, reducers: {
    // Reducers para manejo de archivos
    setFiles(state, action) {
      state.files = action.payload;
      state.isLoading = false;
    },

    addFiles(state, action) {
      state.files = [...state.files, ...action.payload];
      state.isLoading = false;
    },

    removeFile(state, action) {
      state.files = state.files.filter(file => file.id !== action.payload);
      state.isLoading = false;
    },

    setUploadProgress(state, action) {
      state.uploadProgress = action.payload;
    }, // START LOADING

    startLoading(state) {
      state.isLoading = true;
    }, // HAS ERROR

    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },

    // RESET ERROR
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    // SET SAVED ASSET
    setIssue(state, action) {
      state.isLoading = false;
      state.currentIssue = action.payload;
    },

    setAsset(state, action) {
      state.isLoading = false;
      state.asset = action.payload;
    },

    setStatus(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },

    setType(state, action) {
      state.isLoading = false;
      state.type = action.payload;
    },

    setTimePeriod(state, action) {
      state.isLoading = false;
      state.timePeriod = action.payload;
    },

    setMine(state, action) {
      state.mine = action.payload;
    },

    setQuery(state, action) {
      state.query = action.payload;
    },

    setIssueList(state, action) {
      state.isLoading = false;
      state.issueList = action.payload.data;
      state.total = action.payload.total;
    },

    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    onChangeStatus(state, action) {
      state.status = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  setIdSelected,
  setIssue,
  setAsset,
  setMine,
  setQuery,
  setStatus,
  setType,
  setTimePeriod,
  setFiles,
  addFiles,
  removeFile,
  setUploadProgress
} = slice.actions;

// DEFAULTS
const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';


export function deleteIssue(issueId, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/issue/${issueId}`);
      return true
      // dispatch(slice.actions.onChangeStatus('deleted'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveIssue(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle the response
      await axios.post(`/api/issue`, body);
      callOnSubmitted(true);
    } catch (error) {
      callOnSubmitted(false);
    }
  };
}

export function getIssueById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle the response
      const response = await axios.get(`/api/issue/${id}`);
      dispatch(setIssue(response.data));
    } catch (error) {
      errorDialog.msg = `Error finding non compliant issue ${id}`;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function changeStatus(data, action, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/v1/issue/apply/${action}/${data.id}`, data);
      dispatch(slice.actions.setIssue(undefined));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function updateIssue(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/v1/issue/${data.id}`, data);
      dispatch(slice.actions.onChangeStatus('updated'));
      dispatch(slice.actions.setIssue(undefined));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

// Funciones para manejo de archivos
export function uploadFiles(issueId, files) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();

    // Agregar cada archivo al FormData
    files.forEach(file => {
      formData.append('files', file.file);
    });

    try {
      const response = await api2.post(`/api/v1/issue/${issueId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(setUploadProgress(Math.round(progress)));
        }
      });

      // Actualizar la lista de archivos en el estado
      dispatch(addFiles(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      errorDialog.msg = 'Error uploading files';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
      return false;
    }
  };
}

export function deleteFile(issueId, fileId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await api2.delete(`/api/v1/issue/file/${fileId}`);
      dispatch(removeFile(fileId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      errorDialog.msg = 'Error deleting file';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
      return false;
    }
  };
}

export function downloadFile(issueId, fileId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api2.get(`/api/v1/issue/files/${fileId}/download`,
        {
          responseType: 'blob', headers: {
            'Accept': '*/*'
          }
        });

      // Crear URL del blob y descargar
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Obtener el nombre del archivo del header de la respuesta o usar un nombre por defecto
      const contentDisposition = response.headers['content-disposition'];
      console.log('Headers:', response.headers);
      console.log('Content-Disposition:', contentDisposition);
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/['"]/g, '') : `download-${fileId}`;

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      dispatch(slice.actions.stopLoading());
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      errorDialog.msg = 'Error downloading file';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
      return false;
    }
  };
}

export function loadFiles(issueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api2.get(`/api/v1/issue/${issueId}/files`);
      dispatch(setFiles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      errorDialog.msg = 'Error loading files';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function getIssuesList(query, asset_id, page, size, dir, orderBy, timePeriod, status, type, supertype) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const p = {};

    if (page) p.page = page;
    if (asset_id) p.asset_id = asset_id;
    if (size) p.size = size;
    if (dir) p.dir = dir;
    if (orderBy) p.order_by = orderBy;
    if (status !== 'all') p.status = status;
    if (timePeriod) p.time_period = timePeriod;
    if (type !== 'all') p.type = type;
    if (supertype !== 'all') p.supertype = supertype;

    try {
      const response = await axios.get(`/api/issues/${query ? query : '_all_'}`, {params: p});
      dispatch(slice.actions.setIssueList(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
    dispatch(slice.actions.stopLoading());
  }
}


// ----------------------------------------------------------------------


function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  assets: '/assets',
  cruds: '/entities',
  compliance: '/compliance',
  remediation: '/remediation',
  network_audit: '/network_audit',
  reports: '/reports',
  admin: '/admin',
  provisioning: '/provisioning',
  notification: '/notification',
  tasks: '/tasks'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    logout: path(ROOTS.auth, '/logout'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password')
  },
  comingSoon: '/coming-soon',
  callback: '/callback',
  pricing: '/pricing',
};

export const PATH_REMEDIATION = {
  root: ROOTS.remediation,
  remediation: {
    change_requests: path(ROOTS.remediation, '/change_requests'),
    create_change_request: path(ROOTS.remediation, '/change_requests/new'),
    create_change_request_nci: path(ROOTS.remediation, '/change_requests/nci/:nci'),
    edit_change_request: path(ROOTS.remediation, '/change_requests/:id'),
    issues: path(ROOTS.remediation, '/issues'),
    create_issue: path(ROOTS.remediation, '/issues/new'),
    edit_issue: path(ROOTS.remediation, '/issues/:id'),
    sr_issues: path(ROOTS.remediation, '/sr_issues'),
    sr_create_issue: path(ROOTS.remediation, '/sr_issues/new'),
    sr_edit_issue: path(ROOTS.remediation, '/sr_issues/:id'),
    in_issues: path(ROOTS.remediation, '/in_issues'),
    in_create_issue: path(ROOTS.remediation, '/in_issues/new'),
    in_edit_issue: path(ROOTS.remediation, '/in_issues/:id'),
    issues_by_asset: path(ROOTS.remediation, '/issues/asset/:id'),
  }
};

export const PATH_REPORTS = {
  root: ROOTS.reports,
  reports_v2: {
    custom_reports: path(ROOTS.reports, '/v2/custom/all'),
    built_in_reports: path(ROOTS.reports, '/v2/built_in/all'),
    view_report: path(ROOTS.reports, '/v2/:id'),
    report_builder: path(ROOTS.reports, '/v2/builder/main/:id?')
  },
  reports: {
    all_reports: path(ROOTS.reports, '/all'),
    report_detail: path(ROOTS.reports, '/detail/:id'),
    asset_by_vuln: path(ROOTS.reports, '/assets/vuln/:id'),
    asset_by_bug: path(ROOTS.reports, '/assets/bug/:id'),
    asset_by_vuln_severity: path(ROOTS.reports, '/assets/vuln_severity/:id'),
    asset_by_bug_severity: path(ROOTS.reports, '/assets/bug_severity/:id'),
    asset_eox: path(ROOTS.reports, '/assets/eox/:id'),
    asset_eos: path(ROOTS.reports, '/assets/eos/:id'),
    asset_ldos: path(ROOTS.reports, '/assets/ldos/:id'),
    asset_coverage: path(ROOTS.reports, '/assets/coverage/:id'),
    asset_by_type: path(ROOTS.reports, '/assets/type/:id'),
    asset_ss: path(ROOTS.reports, '/assets/ss/:id'),
    asset_issues_by_closed_status: path(ROOTS.reports, '/assets/issue/closed_status/:id'),
    asset_issues_by_status: path(ROOTS.reports, '/assets/issue/status/:id')
  }
}


export const PATH_PROVISIONING = {
  root: ROOTS.provisioning,
  general: {
    create: path(ROOTS.provisioning, '/create'),
    detail: path(ROOTS.provisioning, '/detail/:id'),
  }
};

export const PATH_TASKS = {
  root: ROOTS.tasks,
  general: {
    create: path(ROOTS.tasks, '/detail'),
    main: path(ROOTS.tasks, '/main'),
    detail: path(ROOTS.tasks, '/detail/:id'),
    templates: path(ROOTS.tasks, '/templates'),
    template: path(ROOTS.tasks, '/templates/:id'),
    addTemplate: path(ROOTS.tasks, '/templates/new'),
    create_template_pol: path(ROOTS.tasks, '/templates/policy/:pol'),
    template_file: path('/', 'static/upload/upload_example.csv'),
    workflow: path(ROOTS.tasks, '/workflow'),
    workflow_detail: path(ROOTS.tasks, '/workflow/:id'),
    workflow_create: path(ROOTS.tasks, '/workflow/new'),
    jobs: path(ROOTS.tasks, '/jobs'),
    schedules: path(ROOTS.tasks, '/schedules'),
  }
};

export const PATH_ASSETS = {
  root: ROOTS.assets,
  general: {
    create: path(ROOTS.assets, '/active/create'),
    stock: path(ROOTS.assets, '/active/stock_create'),
    search: path(ROOTS.assets, '/active'),
    main: path(ROOTS.assets, '/active/main'),
    discovery: path(ROOTS.assets, '/active/discovery/:id'),
    detail: path(ROOTS.assets, '/active/detail/:id'),
    bulk_upload: path(ROOTS.assets, '/active/bulk_upload'),
    config_detail: path(ROOTS.assets, '/active/:asset_id/config/:id'),
    config_compare: path(ROOTS.assets, '/active/config/compare/:id1/with/:id2'),
    configs: path(ROOTS.assets, '/active/:id/configs'),
    component: path(ROOTS.assets, '/active/:id/inventory'),
    component_detail: path(ROOTS.assets, '/active/:asset_id/component/:id'),
    component_compare: path(ROOTS.assets, '/active/component/compare/:id1/with/:id2'),
    run: path(ROOTS.assets, '/active/:id/run/:run_id'),
    files_by_upload: path(ROOTS.assets, '/active/files/upload/:id'),
    template_file: path('/', 'static/upload/upload_example.csv'),
    tags: path(ROOTS.assets, '/tags'),
    tag_detail: path(ROOTS.assets, '/tags/:id'),
    tag_create: path(ROOTS.assets, '/tags/new'),
    smart_account: path(ROOTS.assets, '/smart_account'),
    discovery_subnet: path(ROOTS.assets, '/active/subnet_discovery'),
    scan_detail: path(ROOTS.assets, '/active/subnet_discovery/:id'),
    targets: path(ROOTS.assets, '/targets'),
    new_target: path(ROOTS.assets, '/target/new'),
    target_detail: path(ROOTS.assets, `/target/:id`)
  }
};

export const PATH_OFFLINE_ASSETS = {
  root: ROOTS.assets,
  general: {
    create: path(ROOTS.assets, '/offline/create'),
    search: path(ROOTS.assets, '/offline'),
    main: path(ROOTS.assets, '/offline/main'),
    discovery: path(ROOTS.assets, '/offline/discovery/:id'),
    detail: path(ROOTS.assets, '/offline/detail/:id'),
    bulk_upload: path(ROOTS.assets, '/offline/bulk_upload'),
  }
};

export const PATH_GENERAL = {
  root: ROOTS.cruds,
  entities: {
    users: path(ROOTS.cruds, '/users'),
    create_user: path(ROOTS.cruds, '/users/new'),
    edit_user: path(ROOTS.cruds, '/users/:id'),
    user_credentials: path(ROOTS.cruds, '/credentials'),
    user_credential: path(ROOTS.cruds, '/credential/:id'),
    groups: path(ROOTS.cruds, '/groups'),
    create_group: path(ROOTS.cruds, '/groups/new'),
    edit_group: path(ROOTS.cruds, '/groups/:id'),
    account: path(ROOTS.cruds, '/account'),
    tftp: path(ROOTS.cruds, '/tftp'),
    create_agent: path(ROOTS.cruds, '/agents/new'),
    view_agent: path(ROOTS.cruds, '/agent/:id'),
    agents: path(ROOTS.cruds, '/agents'),
    repositories: path(ROOTS.cruds, '/repositories'),
    create_repository: path(ROOTS.cruds, '/repositories/new'),
    edit_repository: path(ROOTS.cruds, '/repositories/:id'),
    tasks: path(ROOTS.cruds, '/tasks'),
    tasks_detail: path(ROOTS.cruds, '/task/:id'),
    environments: path(ROOTS.cruds, '/environments'),
    create_environment: path(ROOTS.cruds, '/environment/new'),
    edit_environment: path(ROOTS.cruds, '/environment/:id'),
  }
};

export const PATH_COMPLIANCE = {
  root: ROOTS.compliance,
  compliance: {
    // Compliance.
    bugs: path(ROOTS.compliance, '/bugs'),
    bug: path(ROOTS.compliance, '/bugs/:bugId'),
    bugsBySeverity: path(ROOTS.compliance, '/bugs/:sevId'),
    vulnerabilities: path(ROOTS.compliance, '/vulnerabilities'),
    vulnerability: path(ROOTS.compliance, '/vulnerabilities/:vulnId'),
    vulnerabilitiesBySIR: path(ROOTS.compliance, '/vulnerabilities/:selSir'),
  }
};

export const PATH_NETWORK_AUDIT = {
  root: ROOTS.network_audit,
  audit: {
    // Network Audit.
    overview: path(ROOTS.network_audit, '/overview'),
    policies: path(ROOTS.network_audit, '/policies'),
    policy: path(ROOTS.network_audit, '/policies/:id'),
    addPolicy: path(ROOTS.network_audit, '/policies/new'),
    policyGroups: path(ROOTS.network_audit, '/policy/groups'),
    policyGroup: path(ROOTS.network_audit, '/policy/group/:id'),
    addPolicyGroup: path(ROOTS.network_audit, '/policy/group/new'),
    bulk_upload: path(ROOTS.network_audit, '/policy/bulk_upload'),
  }
};

export const PATH_NOTIFICATION = {
  root: ROOTS.notification,
  notification: {
    // Notification Audit.
    inapp: path(ROOTS.notification, '/inapp'),
    labels: [path(ROOTS.notification, '/inapp/:systemLabel/:mailId?')]
  }
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    remediation: path(ROOTS.app, '/remediation_dashboard'),
    main: path(ROOTS.app, '/dashboard'),
    lifecycle: path(ROOTS.app, '/lifecycle_dashboard'),
    alerts: path(ROOTS.app, '/alerts_dashboard'),
    snmp: path(ROOTS.app, '/snmp'),
  }
};

import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import objFromArray from '../../utils/objFromArray';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  mails: { byId: {}, allIds: [] },
  labels: [{ id: 1, label: 'label1' }],
  isOpenSidebar: false,
  isOpenCompose: false,
  total: 0,
  query: ''
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;
      state.isLoading = false;
      state.mails.byId = objFromArray(mails);
      state.mails.allIds = mails.map( (m) => m.id);

    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload;

      state.mails.byId[mail.id] = mail;
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },

    updateSuccess(state, action) {
      const mail = action.payload;
      state.mails.byId[mail.id] = mail;
    },

    // SIDEBAR
    openSidebar(state) {
      state.isOpenSidebar = true;
    },

    closeSidebar(state) {
      state.isOpenSidebar = false;
    },

    // COMPOSE
    openCompose(state) {
      state.isOpenCompose = true;
    },

    closeCompose(state) {
      state.isOpenCompose = false;
    },

    setTotal(state, action) {
      state.total = action.payload;
    },

    setQuery(state, action) {
      state.query = action.payload;
    } 
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openSidebar,
  closeSidebar,
  openCompose,
  getMailSuccess,
  getMailsSuccess,
  closeCompose,
  setQuery
} = slice.actions;

export function getLabels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/notifications/labels');
      dispatch(slice.actions.getLabelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function patchById(mail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/api/notification/${mail.id}`, mail);
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteMultiple(mailList) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(mailList)
      const response = await axios.post(`/api/notifications/delete`, mailList);
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}


export function getMails(params, page, query) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const p = {};
    if (page) p.page = page;
    if (query) p.query = query
    try {
      const response = await axios.get(
        `/api/notifications/label/${params.systemLabel}`, { params: p } 
      );
      dispatch(slice.actions.getMailsSuccess(response.data.data));
      dispatch(slice.actions.setTotal(response.data.total))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getMail(mailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/notification/detail/${mailId}`);
      dispatch(slice.actions.getMailSuccess(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  detail: '',
  tagsList: [],
  currentTag: undefined,
  assets: []
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setTagsList(state, action) {
      state.tagsList = action.payload;
      state.isLoading = false;
    },

    setCurrentTag(state, action) {
      state.currentTag = action.payload;
      state.isLoading = false;
    },

    setAssets(state, action) {
      state.assets = action.payload;
      state.isLoading = false;
    },
    clearAssets(state) {
      state.assets = [];
     }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setTagsList, setCurrentTag, setAssets, clearAssets } = slice.actions;

export function getTagsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/tags`);
      dispatch(slice.actions.setTagsList(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTagById(tagId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/tag/${tagId}`);
      dispatch(slice.actions.setCurrentTag(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveTag(body, callOnSubmitted) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.post(`/api/tag`, body);
        dispatch(slice.actions.stopLoading());
        callOnSubmitted(true);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function updateTag(body, callOnSubmitted) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.patch(`/api/tag/${body.id}`, body);
        dispatch(slice.actions.stopLoading());
        callOnSubmitted(true);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function deleteTagById(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.delete(`/api/tag/${id}`);
        dispatch(getTagsList())
        dispatch(slice.actions.stopLoading());
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function getAssetsByTagId(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/api/tag/assets/${id}`);
        dispatch(slice.actions.setAssets(response.data.data.data))
        dispatch(slice.actions.stopLoading());
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }


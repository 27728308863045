import React from 'react';
import PropTypes from 'prop-types';

FilterComponent.propTypes = {
    condition: PropTypes.bool.isRequired,
};

export default function FilterComponent({ condition, children}) {
    if ( condition ) {
        return (
            <>
               {children}
            </>
        );
    }
    return <></>
}